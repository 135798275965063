var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},_vm._l((_vm.customerOrderDetails),function(customerOrderDetail){return _c('v-row',{key:customerOrderDetail.id,staticClass:"order-detail pl-8"},[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(customerOrderDetail.productName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.product-name')))])])],1),_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"order-comment body-2"},on),[_vm._v(" "+_vm._s(_vm.formatComment(customerOrderDetail))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.comment-message')))])])],1),_c('v-col',{staticClass:"body-2 indigo--text darken-4",attrs:{"cols":"2","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(customerOrderDetail.quantity)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.quantity')))])]),_c('span',[_vm._v("x")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatPrice(customerOrderDetail.price))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.price')))])])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"red--text darken-1"},on),[_vm._v(" "+_vm._s(_vm.formatTotalAmount(customerOrderDetail.totalAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.total-amount')))])])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }