import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CustomerOrderDetalService extends AbstractService {
    private controller = "customer-order-details";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public current(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/current`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }
    
    public post(customerOrderDetail: any) {
        const d = { customerOrderDetail };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public put(customerOrderDetail: any) {
        const d = { customerOrderDetail };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public move(customerOrderId: number, customerOrderDetailIds: number[], newOrderStatus: string, subOrder: boolean) {
        const d = { customerOrderId, customerOrderDetailIds, newOrderStatus, subOrder };
        const url = this.makeUrl(`${this.controller}/move`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(customerOrderDetail: any) {
        const d = { customerOrderDetail };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }
}